<template>
  <div class="gallery">
    <div class="swiper gallery">
      <div class="swiper-wrapper">
        <div v-for="item in gallery" class="swiper-slide">
          <span class="title">{{ item.title }}</span>
          <img :src="item.imageUrl" class="image" alt="">
        </div>
      </div>
      <div
          @mouseenter="menuStore.getComponentCursor($event, 'left')"
          @mouseleave="menuStore.getComponentCursor($event, null)"
          @click="slide(-1)"
          class="left-cursor cursor-nav"></div>
      <div
          @mouseenter="menuStore.getComponentCursor($event, 'right')"
          @mouseleave="menuStore.getComponentCursor($event, null)"
          @click="slide(1)"
          class="right-cursor cursor-nav"></div>
    </div>
    <div class="pin"
         @click="changePinStatus(index)"
         :class="{active:pin.active}"
         v-for="(pin,index) in pins"
         :style="{...pin.positions}">
      <div class="pin__label">{{ pin.data.title }}</div>
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="32" cy="32" r="32" fill="white"/>
        <path
            d="M48.2883 22.0955L31.9549 15.0961C31.6653 14.968 31.3346 14.968 31.0451 15.0961L14.7117 22.0955C14.5065 22.1927 14.3318 22.3439 14.2063 22.533C14.0807 22.7223 14.0094 22.9421 14 23.1689V41.8341C14.0003 42.0629 14.0677 42.2869 14.1943 42.4778C14.3208 42.6686 14.5008 42.8181 14.7117 42.9074L31.0451 49.9068C31.3357 50.0311 31.6643 50.0311 31.9549 49.9068L48.2883 42.9074C48.4992 42.8181 48.6792 42.6686 48.8057 42.4778C48.9323 42.2869 48.9997 42.0629 49 41.8341V23.1689C48.9906 22.9421 48.9193 22.7223 48.7937 22.533C48.6682 22.3439 48.4935 22.1927 48.2883 22.0955ZM30.3336 47.0602L16.3336 41.0641V24.942L30.3336 30.9382V47.0602Z"
            fill="#060606"/>
      </svg>
    </div>
    <div class="image-count">
      <span class="title">{{ activeIndex }}/{{ gallery.length }}</span>
    </div>
    <div class="tabs-container">
      <div class="item">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M15.2835 26.0003H0.634812C0.284611 26.0003 0.000652473 25.7164 0.000652473 25.3662V12.9808C3.6142 13.2036 7.05794 14.5934 9.81382 16.9413C12.5698 19.2891 14.4893 22.4678 15.2837 26L15.2835 26.0003ZM26.0012 0.633942V25.3662C26.0012 25.5343 25.9344 25.6957 25.8155 25.8146C25.6966 25.9335 25.5352 26.0003 25.367 26.0003H17.2367C16.4223 21.9536 14.2882 18.2911 11.1692 15.5878C8.04991 12.8838 4.12182 11.2914 0 11.0597V0.634159C0 0.283958 0.283958 0 0.634159 0H25.3664C25.5345 0 25.6959 0.0668136 25.8148 0.185718C25.9337 0.304623 26.0005 0.465995 26.0005 0.634159L26.0012 0.633942ZM21.2577 8.73234C21.2569 7.61832 20.8138 6.55047 20.0257 5.7631C19.2375 4.97609 18.1694 4.53384 17.0553 4.53413C15.9416 4.53441 14.8732 4.97691 14.0855 5.76476C13.2979 6.55235 12.8554 7.62049 12.8551 8.7343C12.8548 9.84832 13.2968 10.9167 14.0841 11.7046C14.8714 12.4928 15.9393 12.9358 17.0533 12.9367C18.1682 12.9367 19.2378 12.4936 20.0262 11.7052C20.8147 10.9167 21.2577 9.84745 21.2577 8.73227V8.73234ZM17.0533 6.43661C16.4435 6.43746 15.8591 6.68037 15.4286 7.1121C14.998 7.54384 14.7567 8.129 14.7576 8.73852C14.7584 9.34834 15.0014 9.9327 15.4331 10.3633C15.8648 10.7936 16.4497 11.0351 17.0595 11.0342C17.6693 11.0334 18.2537 10.7905 18.684 10.3587C19.1146 9.92701 19.3561 9.34184 19.3552 8.73232C19.3544 8.12251 19.1112 7.53815 18.6797 7.10757C18.248 6.67725 17.6628 6.43576 17.0533 6.43661H17.0533Z"
              fill="black"/>
        </svg>
        <span class="title">{{ $fn.tr('Photo Gallery') }}</span>
      </div>
      <div v-if="link.title" class="item">
        <a :href="link.url" target="_blank" class="title">{{ link.title }}</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref} from "vue";
import Swiper from 'swiper'
import {useMenuStore} from "@/store/pinia/menu";

let props = defineProps({
  contentData: {
    type: Object,
  }
})
let menuStore = useMenuStore()

let gallery = computed(() => props.contentData?.data?.list?.[0]?.image?.map(item => ({
  ...item,
  imageUrl: item?.attachedImages?.[0]?.devices?.desktop
})))
let link = computed(() => props.contentData?.data?.list?.[0]?.link || {})
let swiper = ref(null)
let activeIndex = ref(1)

const pins = computed(() => {
  const pinList = gallery.value[activeIndex.value - 1]?.pins;

  return pinList?.map(pin => {
    return {
      positions: {left: pin.x + '%', top: pin.y + '%'},
      data: pin.data[menuStore.getLang] || {},
      active: pin.active
    }
  })
})

let setSwiper = () => {
  swiper.value = new Swiper(".gallery", {
    pagination: {
      el: ".swiper-pagination",
    },
  });

  swiper.value?.[1].on('slideChange', (item) => {
    activeIndex.value = item.activeIndex + 1
  });

}
const slide = (index) => {
  let sw = swiper.value?.[1]

  if (index === 1) sw.slideNext()
  else sw.slidePrev()
}
const changePinStatus = (ind) => {
  const pinList = gallery.value[activeIndex.value - 1]?.pins;
  pinList.forEach((item, index) => {
    index === ind ? item.active = !item.active : item.active = false
  })
}
onMounted(async () => {
  await nextTick()
  setSwiper()
})
</script>

<style lang="scss" scoped>
.gallery {
  height: 100vh;
  position: relative;

  .cursor-nav {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    background: transparent;
    z-index: 1;

    &.left-cursor {
      left: 0;
    }

    &.right-cursor {
      right: 0;
    }
  }

  .swiper-wrapper {
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .title {
      font-size: 89px;
      position: absolute;
      font-weight: 250;
      color: #FFFFFF;
      top: 32px;
      left: 70px;
      font-family: 'HelveticaThin';
      @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        font-size: 55px;
      }
      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  .image-count {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 140px;
    height: 140px;
    background: #DCDCDC;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1023px) {
      width: 80px;
      height: 80px;
    }

    .title {
      font-weight: 400;
      font-size: 34px;
      color: #000000;
      @media (max-width: 1023px) {
        font-size: 21px;
      }
    }
  }

  .pin {
    position: absolute;
    cursor: pointer;
    z-index: 12;

    &__label {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 21px;
      line-height: 25px;
      color: $white;
      background-color: $black;
      border-radius: 19px;
      padding: 4px 13px;
      margin-bottom: 22px;
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }

    svg {
      circle {
        transition: .3s;
      }
    }

    &.active {
      .pin__label {
        opacity: 1;
        visibility: visible;
      }

      svg {
        circle {
          fill: #D9D9D9;
        }
      }
    }

    @include hover {
      .pin__label {
        opacity: 1;
        visibility: visible;
      }
      svg {
        circle {
          fill: #D9D9D9;
        }
      }
    }

    &:focus {
      .pin__label {
        opacity: 1;
        visibility: visible;
      }

      svg {
        circle {
          fill: #D9D9D9;
        }
      }
    }

  }

  .tabs-container {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 1023px) {
      width: 100%;
    }

    .item {
      padding: 10px 17px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        font-weight: 400;
        font-size: 21px;
        margin-left: 10px;
        line-height: unset;
        color: white;
        transition: all .45s ease;
        @media (max-width: 1023px) {
          font-size: 16px;
        }

        &:hover {
          opacity: .5;
        }
      }

      &:first-child {
        background: #FFFFFF;

        .title {
          color: black;
        }
      }
    }
  }
}
</style>